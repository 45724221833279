/*=============================================
=            video cta            =
=============================================*/
#about_us_img {
  @media screen and (max-width: 991px) {
    width: 70%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.img-fluid {
  width: 100%;
  height: auto;
  border-radius: 10px !important;
  @media only screen and (max-width: 768px) {
  }

  &__servicepng {
    width: 70%;
    height: auto;
    border-radius: 10px !important;
  }

  &__footer {
    width: 100%;
    height: auto;
    border-radius: 10px !important;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  &__about_us_img {
    width: 100%;
    height: 567px;
    border-radius: 10px !important;
    padding-bottom: 100px;
  }
}
.services-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
}

.cta-button-group--center {
  text-align: center;
  margin-bottom: 25px;
  padding-top: 25px;
}

.video-cta-content {
  &__welcome-title {
    text-shadow: #91919155 0px 0px 10px;
    background: #000000;
    text-align: center;
    margin-top: -5px;
    font-size: 28px;
    font-weight: 720;
    line-height: 1.5;
    margin-bottom: 15px;
    padding-top: 20px;
    font-family: system-ui;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 991px) {
      font-size: 26px;
    }
  }

  &__us {
    @media #{$large-mobile} {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media #{$extra-small-mobile} {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media #{$tablet-device} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__small-title {
    margin-top: -5px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 15px;
    padding-top: 20px;
  }

  &__title {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 700;
    color: #1d1d25;
    width: 415px;
    max-width: 100%;
    margin-bottom: 30px;

    @media #{$large-mobile} {
      font-size: 30px;
    }
  }

  &__text {
    font-size: 19px;
    color: #1d1d25;
    width: 51%;
    padding: 30px;
    text-align: left;
    line-height: 2;

    @media #{$large-mobile} {
      margin-bottom: 20px;
      font-size: 16px;
      color: #1d1d25;
      width: 100%;
      text-align: center;
      line-height: 1.7;
    }

    @media #{$extra-small-mobile} {
      font-size: 15px;
      color: #1d1d25;
      width: 100%;
      text-align: center;
      line-height: 1.7;
    }
    @media screen and (max-width: 991px) {
      font-size: 15px;
      color: #1d1d25;
      width: 100%;
      text-align: center;
      line-height: 1.7;
    }
  }

  // @media #{$tablet-device}{
  //     margin-bottom: 50px;
  // }

  @media #{$large-mobile} {
    margin-bottom: 30px;
  }
}

.cta-video-image {
  position: relative;
  box-sizing: border-box !important;
  padding-top: 50px;

  @media screen and (max-width: 991px) {
    padding-top: 0px;
  }
  a {
    display: block;
  }
  &__image {
    overflow: hidden;
    img {
      transition: $transition--cubic;
      transition-duration: 0.6s;
      width: 100%;
    }
  }

  &__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $theme-color--default;
    position: absolute;
    line-height: 100px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%) scale(1);
    transition: $transition--cubic;
    transition-duration: 0.6s;
    left: -50px;

    @media #{$tablet-device, $large-mobile} {
      width: 70px;
      height: 70px;
      line-height: 70px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    i {
      font-size: 36px;
      color: $white;
    }
  }

  &:hover {
    .cta-video-image__image {
      img {
        transform: scale(1.1);
      }
    }
    .cta-video-image__icon {
      transform: translateY(-50%) scale(1.1);
      @media #{$tablet-device, $large-mobile} {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-right: 5px solid $theme-color--default;
    border-bottom: 5px solid $theme-color--default;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: -1;
  }

  .video-popup {
    & > button {
      background: none;
      border: none;
      width: 100%;
    }
  }
}

/*=====  End of video cta  ======*/
