
/*=============================================
=            section title            =
=============================================*/

.section-title{
    display: inline-block;
    font-size: 36px;
    color: $theme-color--default;
    font-weight: 700;
    margin-top: -16px;

    @media #{$extra-small-mobile}{
        font-size: 30px;
    }
}

.section-title__header {
    font-size: 36px;
    color: $theme-color--default;
    font-weight: 700;
    margin-top: -16px;
    padding-bottom: 25px;

    @media #{$extra-small-mobile}{
        font-size: 30px;
        padding-bottom: 0px;
    }
    @media #{$large-mobile}{
        font-size: 33px;
        padding-bottom: 0px;
    }
    @media #{$tablet-device}{
        font-size: 34px;
        padding-bottom: 15px;
    }

}

.section-subtitle{
    font-size: 18px;
    line-height: 1.5;
    color: #1d1d25;
    font-family: $poppins;
}

.title-icon{
    width: 4px;
    height: 20px;
    background-color: $theme-color--default;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
}

/*=====  End of section title  ======*/

