button {
    &.scroll-top {
        position: fixed;
        right: 50%;
        transform: translateX(50%);
        bottom: -60px;
        z-index: 999;
        box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
        display: block;
        padding: 0;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        text-align: center;
        font-size: 25px;
        line-height: 60px;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        background-color: $theme-color--default;
        color: $white;
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-color: transparent;

        @media #{$extra-small-mobile} {
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 20px;
        }

        &.show {
            visibility: visible;
            opacity: 1;
            bottom: 60px;
        }
    }
}
