/*=============================================
=            service grid slider            =
=============================================*/

.col-lg-12 {
  padding: 0px;
}
#services {
  text-align: center !important;
}

.text-botton-line {
  //box-shadow: 0 1px 3px 1px #66a3ff;
  background-color: #66a3ff;
  width: 23.5%;
  height: 3px;
  margin: auto;
  border-radius: 100px;
}
#about_us {
  background-color: #f7f7f7;
}

.service-slider {
  .ht-swiper-button {
    &-nav {
      display: none;
    }
  }

  &__container {
    &--style2 {
      .swiper-container {
        padding: 10px;
        margin: -10px;
      }
      margin-top: -245px;
    }
  }
}

.service-grid-item {
  padding-top: 30px;
  &__image {
    position: relative;
    margin-bottom: 60px;
    .icon {
      border-radius: 15px;
      width: 70px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      background-color: #393939;
      position: absolute;
      bottom: -35px;
      left: 20px;
      transition: $transition--cubic;
      @media #{$extra-small-mobile} {
        width: 60px;
        height: 60px;
        line-height: 60px;
      }
        @media #{$large-mobile} {
            width: 65px;
            height: 65px;
            line-height: 65px;
        }
      i {
        color: $white;
        font-size: 40px;
        padding-top: 15px;
        @media #{$extra-small-mobile} {
          font-size: 30px;
        }
        @media #{$large-mobile} {
          font-size: 35px;
        }
      }
    }

    a {
      display: block;
      img {
        width: 100%;
        transition: $transition--cubic;
        transition-duration: 0.6s;
        @media #{$large-mobile} {
          width: 97%;
          padding-left: 20px;
          border-radius: 10px;
        }

        @media #{$extra-small-mobile} {
          width: 95%;
          padding-left: 17px;
          border-radius: 10px;
        }
      }
    }
  }

  &__image-wrapper {
    overflow: hidden;
  }

  &__content {
    line-height: 1;
    .title {
      a {
        font-size: 22px;
        color: #393939;
        font-weight: 700;
        transition: $transition--cubic;
        &:hover {
          color: $theme-color--default;
        }

        @media #{$extra-small-mobile} {
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @media #{$large-mobile} {
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        @media #{$tablet-device} {
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .subtitle {
      font-size: 16px;
      line-height: 1.8;
      margin-bottom: 15px;
      font-family: $poppins;
      color: #5a5a5a;
      @media screen and (max-width: 991px) {
        font-size: 15px;
        text-align: center;
      }
    }
  }

  &:hover {
    .service-grid-item__image {
      .icon {
        background-color: $theme-color--default;
      }

      img {
        transform: scale(1.1);
      }
    }

    .service-grid-item__content {
      .see-more-link {
        color: $theme-color--default;
      }
    }
  }

  &--style2 {
    box-shadow: 0 1px 15.36px 0.64px rgba(0, 0, 0, 0.2);
    .service-grid-item__image {
      margin-bottom: 0;
    }
    .service-grid-item__content {
      background-color: $white;
      padding: 30px;
    }
  }
}

/*=====  End of service grid slider  ======*/
